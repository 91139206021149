import css from 'styled-jsx/css';

export default css`
  .tooltip-container {
    position: relative;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .light {
    background: var(--background-utils);
    color: var(--background-utils);
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.18);
  }
  .light .tooltip-arrow {
    background: var(--background-utils);
  }
  .dark {
    background: var(--foreground);
    color: var(--background-utils);
  }
  .dark .tooltip-arrow {
    background: var(--foreground);
  }
  .top {
  }
  .right {
    left: calc(100% + 6px);
    top: 50%;
    transform: translateY(-50%);
  }
  .left {
    right: calc(100% + 6px);
    top: 50%;
    transform: translateY(-50%);
  }
  .bottom {
    left: 50%;
    top: 110%;
    transform: translate(-50%);
  }
  .tooltip-box {
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    display: none;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    white-space: break-spaces;
    width: auto;
    min-width: 120px;
    max-width: 200px;
    z-index: 10;
    height: auto;
  }
  .tooltip-box-mobile {
    position: absolute;
    width: 180px;
    padding: 10px;
    border-radius: 5px;
    display: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    white-space: break-spaces;
    z-index: 10;
  }
  .tooltip-arrow {
    z-index: 1;
    width: 9px;
    height: 9px;
    content: '';
    position: absolute;
    border-radius: 0px 0px 2px;
    transform: rotate(-135deg);
    border-right: 1px solid rgba(145, 158, 171, 0.12);
    border-bottom: 1px solid rgba(145, 158, 171, 0.12);
  }
  .right .tooltip-arrow {
    left: -4px;
  }
  .left .tooltip-arrow {
    right: -4px;
  }
  .bottom .tooltip-arrow {
    top: -4px;
  }
  .tooltip-box.visible {
    display: flex;
  }
  .tooltip-box-mobile.visible {
    display: flex;
  }
  .fullWidth {
    width: 100%;
  }
`;
