import css from 'styled-jsx/css';

export default css`
    .title-aidify {
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
        margin-bottom: 5px;
    }

    .container-aidify {
        padding: 10px 22px;
        margin-top: 20px;
        background-color: rgba(234, 27, 181, 0.25);
    }

    .sidenav {
        grid-area: sidenav;
        top: 0px;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;
        position: fixed;
        background: var(--bg-sidenav);
        border-right: var(--line);
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 80px;
        width: 250px;
    }

    .sidenav.active {
        box-sizing: border-box;
        z-index: 11;
        transform: translateX(-250px);
        transition: 0.3s;
    }

    .sidenav.inactive {
        transition: 0.3s;
        z-index: 11;
        transform: translateX(0px);
    }

    @media only screen and (min-width: 992px) {
        .sidenav {
            transition: all 0s ease !important;
            grid-area: sidenav;
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 100vh;
            transform: translateX(0px) !important;
            position: fixed;
            background: var(--bg-sidenav);
            border-right: var(--line);
            padding-bottom: 0px;
        }

        .sidenav .top-section .close-btn {
            display: none;
        }

        .social-icon a {
            cursor: pointer;
            margin-bottom: var(--margin-md);
        }

        .sidenav__top-section {
            position: sticky;
            height: 63px;
            background: var(--background-utils);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0em 1em;
            z-index: 2;
            border-bottom: var(--line-header);
        }
    }

    .sidenav::-webkit-scrollbar {
        width: 0.1em;
        position: absolute;
    }

    .sidenav::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
        position: absolute;
    }

    .sidenav::-webkit-scrollbar-thumb {
        background-color: var(--foreground);
        outline: 1px solid var(--foreground);
        position: absolute;
    }

    .sidenav__top-section {
        position: sticky;
        height: 62px;
        background: var(--background-utils);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 16px 1em 22px;
        z-index: 2;
        border-bottom: var(--line-header);
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgb(0, 0, 0, 50%);
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;
        z-index: 11;
    }

    .user-menu-title {
        cursor: pointer;
        font-weight: 600;
    }

    .user-menu-title__icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        color: white;
        justify-content: center;
        background: #fff3;
        transition: .3s;
        box-shadow: var(--shadow-light);
    }

    .user-menu-title:hover {
        text-decoration: underline;
        opacity: .85;
        transition: .3s;
    }

    .user-menu-title p {
        font-size: 15px;
        letter-spacing: .5px;
        font-weight: 600;
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .overlay-body {
        overflow: hidden;
    }

    .menu-import li.import-list {
        cursor: inherit;
    }

    .dot-container {
        width: 24px;
        height: 24px;
    }

    .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgb(145, 158, 171);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .active-name .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--primary);
        box-shadow: 0px 0px 14px var(--primary);
    }

    .menu, .menu-import {
        list-style-type: none;
        text-align: left;
        padding: 0;
    }

    .menu li, .menu-import li {
        padding: 10px 0px 10px 16px;
        margin: 2px;
        display: flex;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.2px;
        transition: .3s;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        background-color: transparent;
        -webkit-tap-highlight-color: transparent;
        border-left: solid 4px var(--bg-sidenav);
        color: var(--li-sidenav);
        gap: 10px;
    }

    .menu li::before, .menu-import li::before {
        top: 0px;
        right: 0px;
        width: 3px;
        display: block;
        bottom: 0px;
        content: '';
        display: none;
        position: absolute;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        transition: .3s;
        background-color: rgb(0, 171, 85);
    }

    .catalog-li .import-list-li :hover {
        background: var(--hover-sidenav-li);
        border-color: var(--hover-sidenav-border);
    }

    .catalog-li li a {
        margin: 0px;
        max-width: 140px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .menu li.active :global(.icon), .menu-import li.active :global(.icon) {
        font-weight: 500;
        background: var(--active-icon);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .menu li svg {
        width: 28px;
        height: 28px;
    }

    .menu li:hover {
        background: var(--hover-sidenav-li);
        border-color: var(--hover-sidenav-border);
    }

    .catalog-li li:hover {
        background: var(--hover-sidenav-li);
        border-color: var(--hover-sidenav-border);
    }

    .menu li:hover .import-list {
        background: none !important;
    }

    .menu li.active, .menu-import li.active {
        font-weight: 700;
        color: var(--active-icon);
        background: var(--li-sidenav-active);
        border-left: solid 4px var(--active-icon);
    }

    .menu li.active-name :global(.icon), .menu-import li.active-name {
        color: var(--foreground);
    }

    .menu-import li.active-name {
        font-weight: 700;
    }

    .fg-05 {
        flex-grow: 0.5;
    }

    .dropdown-container.active {
        display: block;
        opacity: 1;
        transition: all 0.3s ease;
        color: var(--active-icon);
    }

    .dropdown-container.none {
        opacity: 0;
        display: none;
    }

    .dropdown-container ul li a {
        margin: 0px;
    }

    .icon-chevron-right {
        position: absolute;
        font-size: 18px;
        right: 16px;
        transition: all 0.3s ease;
    }

    .icon-chevron-right.active {
        position: absolute;
        font-size: 18px;
        right: 16px;
        transform: rotate(90deg);
        transition: all 0.3s ease;
    var(--grey-dark);
        font-weight: 500;
    }

    .active-sub .sub-menu {
        background-color: var(--accent);
        width: 5px;
        height: 5px;
        box-shadow: 0px 0px 0px 6px #2cffd640;
    }

    .dropdown-container ul .active-sub li {
        font-weight: 500;
        background: var(--active-icon);
    }

    .sub-menu {
        width: 5px;
        height: 5px;
        display: flex;
        border-radius: 50%;
        margin: 10px 4px;
        align-items: center;
        justify-content: center;
        background-color: var(--grey-lighter);
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .tab-catalog li {
        margin: 0px 0px 0px 2px;
    }

    .active-sub a {
        font-weight: 500;
    }

    .import-list {
        padding: 10px 0px 10px 20px;
    }

    .import-list > div {
        position: absolute;
        right: 12px;
    }

    .import-list p {
        font-size: 12px;
        letter-spacing: 0.6px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .divider {
        width: 90%;
        height: 1px;
        margin: auto;
        background: #afcaf838;
    }

    .footer ul {
        text-align: left;
        display: flex;
        align-items: flex-start;
    }

    .footer li {
        padding: 6px 0px 6px 0px;
        font-size: 13px;
        cursor: pointer;
    }

    .admin-section li a {
        margin: 0px;
    }

    .logo-sidenav {
        background-image: var(--logo-login);
        height: 50px;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 150px;
        background-size: contain;
        margin: 0px 20px;
        background-position: center;
    }

    .active-name-import :global(.icon):not(.tooltip-container .icon) {
        color: var(--primary-icon);
    }

    .active-name-import {
        background-color: var(--li-sidenav-active) !important;
        border-left: solid 3px var(--primary) !important;
        color: var(--active-icon) !important;
        font-weight: 700 !important;
    }

    .collapse-sticky {
        position: sticky;
        bottom: 0;
        z-index: 5;
        cursor: pointer;
        transition: 0.3s;
    }

    .collapse-sticky p {
        font-size: 13px;
    }

    .minimize {
        padding: 0.5em;
        border-radius: 50px;
    }

    .minimize:hover {
        background: var(--background);
    }

    .title {
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 2px;
        padding: 15px 20px 9px;
        text-transform: uppercase;
        color: var(--title-sidenav);
    }

    .absolute-icon {
        position: absolute;
        right: 10px;
        top: 8px;
    }

    .button-first-list {
        padding: 10px 16px;
        display: flex;
    }

    .upgrade-banner {
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .menu-card {
        padding: 25px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .menu-card__upgrade {
        display: inline-block;
        height: 250px;
        background: var(--upgrade-background);
        border-radius: 8px;
        padding: 16px 20px;
        height: 200px;
        display: flex;
        border: solid thin var(--card-border-pricing);
        margin-bottom: 16px;
    }

    .menu-card__upgrade span {
        font-weight: 700;
        font-size: 18px;
        color: var(--primary);
        margin-top: 30px;
    }

    .menu-card__upgrade p {
        font-weight: 400;
        font-size: 14px;
    }

    .menu-card__circle {
        position: absolute;
        background: var(--background-utils);
        border-radius: 50px;
        padding: 10px;
        left: 50%;
        top: -30px;
        transform: translate(-50%);
        border: solid thin var(--card-border-pricing);
    }

    .menu-card__circle img {
        width: 45px;
    }

    .fg-1 {
        flex-grow: 1;
    }

    :global(.button.cta-upgrade) {
        border-radius: 5px;
    }

    .promo-label {
        text-transform: uppercase;
        background: black;
        padding: 2px 4px;
        color: white !important;
        background: linear-gradient(180deg, #005f4d, #008a73);
        border: 2px solid #02fbbf;
        border-radius: 4px;
    }

    .disabled-action {
        color: var(--grey-lighter) !important;
    }

    .nav-link {
        text-decoration: none;
    }

    .title_section {
        padding: 18px 0px 10px 18px;
        border-top: var(--line);
    }

    .title_section h5 {
        font-size: 18px;
        letter-spacing: .6px;
        font-weight: 600;
    }

    .role-user {
        font-size: 14px;
        font-weight: 700;
    }

    .bsocial__role {
        background: -webkit-linear-gradient(var(--accent-dark), #09a5ec);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .plugin__role {
        color: #005eff !important;
    }

    .plugin_plus__role {
        color: #093176 !important;
    }

    .shoes-list :global(svg g) {
        stroke-width: 1.2px !important;
    }

    .underwear-list :global(svg path) {
        stroke-width: 2px !important;
    }
`;
