import useTranslation from 'hooks/useTranslation';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';
import useResponsive from '../../hooks/useResponsive';
import SidebarFree from './SidebarFree';
import HeaderFree from './HeaderFree';
import { setChannelcode, setRedirectUrl } from 'actions/ui';
import { useRouterWithLang } from 'hooks/useRouterWithLang';
import PopupNewSignup from '../../components/generic/PopupNewSignup';

function Dashboard({ children, isIframe }) {
  const { TRANSLATIONS, translationsParameters, locale } = useTranslation();
  const isEmbedded = useIsEmbedded();
  const { isMobile } = useResponsive();
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouterWithLang();
  const userIsLoading = useSelector((state) => state.userIsLoading);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const user = useSelector((state) => state.user);
  const userFree = PERMISSIONS.IS_FREE_USER || PERMISSIONS.IS_ONLY_SOCIAL;
  const menuOpenHandler = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);
  const [inactive, setInactive] = useState(false);
  const redirectUrl = useSelector((state) => state.UI.redirectUrl);
  const dispatch = useDispatch();
  const handleInactive = useCallback(() => {
    setInactive(!inactive);
  }, [inactive]);
  const channelcode = useSelector((state) => state.UI.channelcode);
  const showNewSignupPopup = useSelector((s) => s.showNewSignupPopup);
  const { lang } = router.query;

  useEffect(() => {
    if (menuOpen || inactive) {
      document.body.classList.add('overlay-body');
    } else document.body.classList.remove('overlay-body');
  }, [inactive, menuOpen]);

  useEffect(() => {
    if (window.location.origin === process.env.DASHBOARD_URL) {
      hotjar.initialize(2013607, 6);
      hotjar.identify(user._id, { email: user.email, language: locale });
    }
  }, [locale, user._id, user.email]);

  useEffect(() => {
    if (redirectUrl) {
      dispatch(setRedirectUrl({ redirectUrl: '' }));
    }
  }, [dispatch, redirectUrl]);

  useEffect(() => {
    if (channelcode) {
      dispatch(setChannelcode({ channelcode: '' }));
    }
  }, [channelcode, dispatch, redirectUrl]);

  const conditionMessageFree =
    TRANSLATIONS.extraField.message !== null &&
    !isEmbedded &&
    userFree &&
    user?.roles?.[0]?.name !== 'shopify-trial';

  const conditionMessage =
    TRANSLATIONS.extraField.message !== null &&
    !isEmbedded &&
    router.pathname === '/' &&
    !userFree &&
    user?.roles?.[0]?.name !== 'shopify-trial';

  if (userIsLoading) {
    return null;
  }

  if (!isLoggedIn && typeof window !== 'undefined') {
    return !!router.push('/');
  }

  if (router.pathname.startsWith('/admin') && !PERMISSIONS.IS_ADMIN) {
    return !!router.push('/');
  }

  if (isEmbedded) {
    return (
      <>
        <main className="main" onClick={() => menuOpen && menuOpenHandler()}>
          <div className={`content`}>{children}</div>
        </main>
        <style jsx>{`
          .top-banner {
            position: absolute !important;
            z-index: 8;
            box-sizing: border-box;
            align-items: center;
            background: #e1fe00;
            top: 55px;
            border-bottom: var(--line-header);
          }
          .top-banner-min,
          .top-banner-max {
            left: 0px;
            width: 100%;
          }
          .margin-content {
            margin-top: 45px;
          }
          .main {
            padding: 12px;
            flex-grow: 1;
            display: flex;
          }
          @media only screen and (min-width: 992px) {
            .top-banner {
              position: fixed;
              z-index: 8;
              box-sizing: border-box;
              align-items: center;
              background: #dc3a3a;
              top: 55px;
              border-bottom: var(--line-header);
            }
            .top-banner {
              top: 65px;
            }
            .top-banner-min {
              left: 88px;
              width: calc(100% - 88px);
            }
            .top-banner-max {
              left: 250px;
              width: calc(100% - 250px);
            }
            .margin-content {
              margin-top: 50px;
            }
            .top-banner-message-box p {
              color: black;
              font-size: 13px;
              font-weight: 400;
              display: flex;
              gap: 5px;
              align-items: center;
            }
            .top-banner-message-box {
              padding: 0.7em;
              width: 100%;
            }
          }
          .top-banner-message-box {
            padding: 0.3em;
            width: 100%;
          }
          .top-banner-message-box p {
            color: black;
            font-size: 13px;
            font-weight: 600;
            display: flex;
            gap: 5px;
            align-items: center;
          }
          :global(body) {
            background-color: var(--background);
          }
          .overlay-body {
            overflow: hidden;
          }

          .content {
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
          }
          @media only screen and (min-width: 992px) {
            .main {
              padding: 16px 8px;
              flex-grow: 1;
              display: flex;
            }
            .content {
              margin-left: auto;
              margin-right: auto;
              flex-wrap: wrap;
              width: 100%;
              box-sizing: border-box;
            }
            @media only screen and (min-width: 768px) {
              .main {
                padding: 16px 32px;
              }
            }
            @media only screen and (min-width: 28em) {
              .content {
                max-width: 1440px;
              }
            }
            .no-padding {
              padding: 0 !important;
            }
          }
        `}</style>
      </>
    );
  }

  return (
    <div className={`layout-dashboard`}>
      {conditionMessageFree && (
        <div className={`top-banner ${!userFree && 'top-banner__fixed'}`}>
          <div className={'top-banner-message-box d-f fd-c jc-c ai-c'}>
            <p className={'ta-c'}>
              {translationsParameters(TRANSLATIONS.extraField.message, [
                // eslint-disable-next-line react/jsx-key
                <a href={TRANSLATIONS.extraField.href} rel="noreferrer">
                  {TRANSLATIONS.extraField.cta}
                </a>,
              ])}
            </p>
          </div>
        </div>
      )}
      <div className={`${!isMobile && !userFree && 'grid-container'}`}>
        {userFree && (
          <>
            <HeaderFree menuOpenHandler={menuOpenHandler} />
            <SidebarFree
              menuOpen={menuOpen}
              menuOpenHandler={menuOpenHandler}
            />
          </>
        )}
        {!userFree && (
          <>
            <Header inactive={inactive} handleInactive={handleInactive} />
            {!userFree && (
              <Sidebar
                inactive={inactive}
                handleInactive={handleInactive}
                userFree={userFree}
              />
            )}
          </>
        )}
        <main
          className={`main ${isIframe ? 'no-padding' : ''} ${
            userFree && 'm-h'
          }`}
        >
          <div className={`content`}>
            {conditionMessage && (
              <div className={`top-banner relative`}>
                <div className={'top-banner-message-box d-f fd-c jc-c ai-c'}>
                  <p className={'ta-c'}>
                    {translationsParameters(TRANSLATIONS.extraField.message, [
                      // eslint-disable-next-line react/jsx-key
                      <a href={TRANSLATIONS.extraField.href} rel="noreferrer">
                        {TRANSLATIONS.extraField.cta}
                      </a>,
                    ])}
                  </p>
                </div>
              </div>
            )}
            {showNewSignupPopup && lang === 'it_IT' && <PopupNewSignup />}
            {children}
          </div>
        </main>
        <Footer />
      </div>
      <style jsx>{`
        .top-banner {
          z-index: 2;
          box-sizing: border-box;
          align-items: center;
          padding: 4px;
          background: var(--banner-top);
          grid-area: banner;
        }
        .top-banner-message-box p {
          color: var(--top-banner-p);
          font-size: 13px;
          font-weight: 500;
        }
        :global(body) {
          background-color: var(--background);
        }
        .overlay-body {
          overflow: hidden;
        }
        .content {
          margin-left: auto;
          margin-right: auto;
          flex-wrap: wrap;
          width: 100%;
          box-sizing: border-box;
        }
        .grid-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 105px 1fr 600px;
          grid-template-areas:
            'header'
            'main'
            'footer';
          height: 100vh;
          background: var(--background);
        }

        @media only screen and (min-width: 768px) {
          .main {
            padding: 16px 32px;
            grid-area: main;
          }
          .grid-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 94px 1fr 500px;
            grid-template-areas:
              ' header'
              ' main'
              ' footer';
            height: 100vh;
            background: var(--background);
          }
        }

        @media only screen and (min-width: 992px) {
          .main {
            grid-area: main;
            padding: 16px 8px;
            flex-grow: 1;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
          }
          .content {
            margin-left: auto;
            margin-right: auto;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
          }
          .grid-container {
            display: grid;
            grid-template-columns: 250px 1fr;
            grid-template-rows: 107px 1fr 600px;
            grid-template-areas:
              'sidenav header'
              'sidenav main'
              'sidenav footer';
            height: 100vh;
            background: var(--background);
          }
          .grid-container-top-banner {
            display: grid;
            grid-template-columns: 250px 1fr;
            grid-template-rows: 40px 105px 1fr 600px;
            grid-template-areas:
              'banner'
              'sidenav header'
              'sidenav main'
              'sidenav footer';
            height: 100vh;
            background: var(--background);
          }
          .top-banner {
            z-index: 2;
            box-sizing: border-box;
            align-items: center;
            padding: 0px;
            background: var(--banner-top);
          }
          .top-banner {
            width: 100%;
          }
          .top-banner-message-box p {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
          .top-banner-message-box a {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 700;
            text-decoration: underline;
          }
          .top-banner-message-box {
            padding: 0.4em;
            width: 100%;
          }
        }
        @media only screen and (min-width: 28em) {
          .content {
            max-width: 1440px;
          }
        }
        .main {
          padding: 16px;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          grid-area: main;
        }
        .m-h {
          min-height: 50vh;
        }
        .no-padding {
          padding: 0 !important;
        }
      `}</style>
    </div>
  );
}

export default Dashboard;
