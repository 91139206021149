import useResponsive from 'hooks/useResponsive';
import { useState } from 'react';

import style from './tooltip.style';

const Tooltip = ({
  children,
  className,
  html,
  light,
  text,
  containerClassName,
  fullWidth = false,
}) => {
  const { isMobile } = useResponsive();
  const [show, setShow] = useState(false);

  return (
    <div className={`tooltip-container ${containerClassName}`}>
      <div
        className={`${className} ${
          isMobile ? 'tooltip-box-mobile' : 'tooltip-box'
        } ${show ? 'visible' : ' '} ${light ? 'light' : 'dark'}`}
      >
        {text}
        {html}
        <span className="tooltip-arrow" />
      </div>
      <div
        className={`${fullWidth ? 'fullWidth' : ''}`}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setTimeout(() => setShow(false), 10)}
      >
        {children}
      </div>
      <style jsx>{style}</style>
    </div>
  );
};
export default Tooltip;
