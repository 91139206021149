import SelectLanguage from 'components/generic/SelectLanguage';
import UserMenu from 'components/generic/UserMenu';
import UserMenuMobile from 'components/generic/UserMenuMobile';
import { DARK_THEME_NAME } from 'tools/constants';
import DrawerUser from 'components/utils/DrawerUser';
import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ConfirmMailDesktop from 'components/generic/ConfirmMailDesktop';
import Notifications from '../../components/generic/Notifications';
import { Link } from 'components/utils/Link';
import style from './header.style';
import SubHeader from './SubHeader';
import CartModal from '../../components/cart/cart-modal';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function Header({ handleInactive }) {
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const router = useRouterWithLang();
  const { resolvedTheme } = useTheme();
  const userEcredit = useSelector((state) => state.user?.ecredit) || '';
  const [activeMenu, setActiveMenu] = useState({ index: -1 });
  const handleMenu = useCallback(({ index }) => {
    setActiveMenu({ index });
  }, []);
  const UI = useSelector((state) => state?.UI) || [];
  const message = UI?.show_confirmation_email_message;

  useEffect(() => {
    if (activeMenu.index === 1) {
      document.body.classList.add('overlay-body');
    } else document.body.classList.remove('overlay-body');
  }, [activeMenu]);

  return (
    <div
      className={`header__fixed ${
        isMobile && activeMenu.index === 1 && 'zindex-custom'
      }`}
    >
      {isSmallDevice && (
        <DrawerUser
          visible={activeMenu?.index !== -1}
          onCloseDrawer={() => {
            handleMenu({ index: -1 });
          }}
        >
          <UserMenuMobile handleMenu={handleMenu} activeMenu={activeMenu} />
        </DrawerUser>
      )}
      <div className={'header__menu'}>
        <div className={`header__menu-wrap-wide ai-c d-f`}>
          <div className={'header__menu-wrap-left'}>
            <div
              className={'header-tablet header__menu-wrap-left-menu'}
              onClick={handleInactive}
            >
              <Icon type="menu" />
            </div>
            <Link href="/">
              <div className="header__menu-wrap-center-logo ai-c d-f" />
            </Link>
          </div>
          <div className={'header__menu-wrap-right'}>
            <div
              className={`header__menu-wrap-right-box d-f ai-c ${
                isMobile ? 'g-2' : 'g-10'
              }`}
            >
              {!isSmallDevice && false && message && <ConfirmMailDesktop />}
              {!isSmallDevice && userEcredit && (
                <div
                  onClick={() => {
                    router.push('/profile/ecredit');
                  }}
                >
                  {resolvedTheme === DARK_THEME_NAME ? (
                    <img
                      src="/images/Icon-ecredit-dark.svg"
                      alt={'Icon-ecredit-dark'}
                    />
                  ) : (
                    <img
                      src="/images/ecredit/Icon-ecredit.svg"
                      alt={'Icon-ecredit'}
                    />
                  )}
                </div>
              )}
              <Notifications />
              <CartModal resolvedTheme={resolvedTheme} />
              {!isSmallDevice && (
                <>
                  <SelectLanguage />
                  <UserMenu />
                </>
              )}
              {isSmallDevice && (
                <div className="user">
                  <button
                    className={'action-trasparent'}
                    onClick={() => {
                      handleMenu({ index: 1 });
                    }}
                  >
                    <Icon
                      type="user"
                      style={{ fontSize: 22, margin: 'auto' }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isMobile && <SubHeader />}
      <style jsx>{style}</style>
    </div>
  );
}

export default Header;
