import Icon from 'components/utils/Icon';
import OutsideClickHandler from 'components/utils/OutsideClickHandler';
import useTranslation from 'hooks/useTranslation';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConfirmedMessage } from 'actions/ui';
import Button from 'components/utils/Button';
import message from 'components/utils/message';
import { resendEmail } from 'actions/user';

function ConfirmMailDesktop() {
  const { TRANSLATIONS } = useTranslation();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleClose = () => {
    setShow(false);
  };

  const closeConfirmMessage = useCallback(() => {
    dispatch(closeConfirmedMessage());
  }, [dispatch]);

  const sendEmail = useCallback(async () => {
    try {
      await dispatch(resendEmail(user.email));
    } catch (e) {
      console.log('error resend email', e);
    } finally {
      dispatch(closeConfirmedMessage());
      message.success(TRANSLATIONS.home.emailSent);
    }
  }, [TRANSLATIONS.home.emailSent, dispatch]);

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className="mail relative">
        <button onClick={() => setShow((show) => !show)}>
          <Icon type="mail" style={{ fontSize: 22 }} />
        </button>
        <span className="alert-email"></span>
        <div className={show ? 'mail-message active' : 'mail-message noactive'}>
          <span>
            {TRANSLATIONS.home.emailConfirm} {TRANSLATIONS.home.checkEmail}
          </span>
          <div className="g-5 mt-md d-f">
            <Button
              className={`small wide btn-white`}
              label={TRANSLATIONS.home.resendEmail}
              onClick={sendEmail}
            />
            <Button
              className={`small wide action-trasparent`}
              label={TRANSLATIONS.home.close}
              onClick={closeConfirmMessage}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .noactive {
          display: flex;
          position: absolute;
          right: 0px;
          top: 40px !important;
          transition: 0.3s;
          visibility: hidden;
          opacity: 0 !important;
        }
        .active {
          transition: 0.3s;
          opacity: 1 !important;
          right: 0px;
          top: 53px;
          transform: translateY(0px);
          z-index: 11;
        }
        .alert-email {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 6px;
          right: 6px;
          background: var(--warning);
          border-radius: 100%;
        }
        .mail button {
          border-radius: 50px;
          height: 40px;
          width: 40px;
          background: transparent;
          cursor: pointer;
        }
        .mail-message {
          display: flex;
          position: absolute;
          background: #fffbea;
          border: solid thin var(--warning);
          box-shadow: 0 10px 20px rgb(0 0 0 / 10%), 0 6px 6px rgb(0 0 0 / 15%);
          flex-direction: column;
          padding: 16px;
          border-radius: var(--radius-md);
          min-width: 310px;
          color: var(--grey-dark);
        }
        .mail-message span {
          font-size: 14px;
          font-weight: 400;
          cursor: default;
        }
      `}</style>
    </OutsideClickHandler>
  );
}

export default ConfirmMailDesktop;
